import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  useInsertEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
} from '@crew/apis/project/projectApis'
import dayjs from '@crew/modules/dayjs'
import { JsonDateFormat } from '@crew/enums/system'
import { ValidateRules } from '@crew/utils/form'
import { UploadFile } from 'models/domain/uploadFile'
import {
  ChangeEventScopeType,
  EntityType,
  EventScope,
  EventType,
  RecurrenceRuleWithNone,
} from '@crew/enums/domain'
import { useAppDispatch } from 'states/hooks'
import { useTranslation } from '@crew/modules/i18n'
import {
  notifyEventEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'
import { Event as EventEntry } from '@crew/models/domain'
import { Event } from '@crew/apis/project/models/getEvent/response'
import { updateIsExpandedDescription } from 'features/project/components/eventDetailPage/states/eventDetailPageSlice'
import { useProjectMemberDataSource } from 'hooks/dataSource/useProjectMemberDataSource'
import {
  useEventScopeDataSource,
  useEventTypeDataSource,
  useRecurrenceOptionDataSource,
  useTaskEntityTypeDataSource,
} from 'hooks/dataSource/useResourceDataSource'
import { useEntityRecordDataSource } from 'hooks/dataSource/useEntityRecordDataSource'
import { useUserDataSource } from 'hooks/dataSource/useUserDataSource'
import {
  useLazyGetLookupEventKindQuery,
  useLazyGetLookupEventKindsQuery,
} from '@crew/apis/lookup/lookupApis'
import { useDataSource } from 'hooks/dataSource/useDataSource'

export type FormValues = {
  eventKindId: string | null
  subject: string
  startDate: Date | null
  startTime: Date | null
  endDate: Date | null
  endTime: Date | null
  confirmAttendance: boolean
  eventAttendeeIds: string[]
  description: string
  // TODO: Web: イベント分類の非表示対応
  // https://break-tmc.atlassian.net/browse/CREW-15049
  // eventCategoryId: string | null
  needNotification: boolean
  scope: EventScope
  allowNonAttendees: boolean
  approvalRequired: boolean
  recurrenceRule: RecurrenceRuleWithNone
  recurrenceEndDate: Date | null
  eventType: EventType
  entityType: EntityType
  entityRecordId: string
  isAllDay: boolean
}
export const formInitialValues: FormValues = {
  eventKindId: null,
  subject: '',
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  confirmAttendance: false,
  eventAttendeeIds: [],
  description: '',
  // TODO: Web: イベント分類の非表示対応
  // https://break-tmc.atlassian.net/browse/CREW-15049
  // eventCategoryId: null,
  needNotification: false,
  scope: EventScope.Public,
  allowNonAttendees: false,
  approvalRequired: false,
  recurrenceRule: RecurrenceRuleWithNone.None,
  recurrenceEndDate: null,
  eventType: EventType.Personal,
  entityType: EntityType.Project,
  entityRecordId: '',
  isAllDay: false,
}
export const useEventScheduleEntryForm = (
  entityType: EntityType,
  entityRecordId: string | undefined,
  targetDate: Date,
  eventType: EventType
) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // react-hook-formの各種データを取得
  const {
    handleSubmit,
    control,
    formState,
    reset,
    getValues,
    setValue,
    clearErrors,
    setError,
    trigger,
  } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: {
      ...formInitialValues,
    },
  })

  // Get functions for registering, updating, and deleting event
  const [insertEventMutation, { isLoading: isLoadingInsertEvent }] =
    useInsertEventMutation()
  const [updateEventMutation, { isLoading: isLoadingUpdateEvent }] =
    useUpdateEventMutation()
  const [deleteEventMutation, { isLoading: isLoadingDeleteEvent }] =
    useDeleteEventMutation()
  const [lazyGetEventKindsQuery] = useLazyGetLookupEventKindsQuery()
  const [lazyGetEventKindQuery] = useLazyGetLookupEventKindQuery()

  const recurrenceOptionDataSource = useRecurrenceOptionDataSource(targetDate)
  const eventAttendeeDataSource = useProjectMemberDataSource(entityRecordId)
  const memberDataSource = useUserDataSource()

  const eventKindDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (eventType === EventType.Project && !entityRecordId) return []

        const response = await lazyGetEventKindsQuery({
          entityType: entityType,
          entityRecordId:
            eventType === EventType.Project ? entityRecordId : undefined,
          name: undefined,
          eventKindIds: undefined,
        }).unwrap()

        return response.eventKinds
      },
      byKey: async (eventKindId: string) => {
        const response = await lazyGetEventKindQuery({
          eventKindId,
        }).unwrap()

        return response.eventKind
      },
    }),
    [
      entityRecordId,
      entityType,
      eventType,
      lazyGetEventKindQuery,
      lazyGetEventKindsQuery,
    ]
  )

  //set data source for entity type
  const entityTypeDataSource = useTaskEntityTypeDataSource()

  //get entity record datasource
  const entityRecordDataSource = useEntityRecordDataSource(entityType)

  //set data source for project scope
  const eventScopeDataSource = useEventScopeDataSource()

  // set data source for event type
  const eventTypeDataSource = useEventTypeDataSource()

  // Insert event
  const insertEvent = useCallback(
    async (
      data: FormValues,
      startDatetime: string,
      endDatetime: string,
      uploadedFileList: UploadFile[]
    ) => {
      const result = await insertEventMutation({
        event: {
          eventType: data.eventType,
          eventKindId: data.eventKindId ?? undefined,
          subject: data.subject as string,

          startDatetime: startDatetime ?? undefined,
          endDatetime: endDatetime ?? undefined,

          scope: data.scope,
          projectId: entityRecordId,

          eventAttendeeIds: data.eventAttendeeIds ?? undefined,

          confirmAttendance: data.confirmAttendance ?? false,
          allowNonAttendees: data.allowNonAttendees ?? false,
          approvalRequired: data.approvalRequired ?? false,

          description: data.description as string,
          needNotification: data.needNotification,

          // TODO: イベント登録APIへのリクエスト処理にタスクを追加する
          // https://break-tmc.atlassian.net/browse/CREW-1220
          tasks: undefined,
          files: uploadedFileList.length > 0 ? uploadedFileList : undefined,
          recurrenceRule:
            data.recurrenceRule !== RecurrenceRuleWithNone.None
              ? data.recurrenceRule
              : undefined,
          recurrenceEndDate: data.recurrenceEndDate
            ? dayjs(data.recurrenceEndDate).format(JsonDateFormat.YYYYMMDD)
            : undefined,
          isAllDay: data.isAllDay,
        },
      }).unwrap()

      if (result.event) {
        const objectEventMessage: ObjectEventMessage<EventEntry> = {
          eventType: NotifyEventType.Inserted,
          id: result.event.id,
          object: result.event,
        }
        dispatch(notifyEventEvent(objectEventMessage))
      }

      return result
    },
    [dispatch, insertEventMutation, entityRecordId]
  )

  // Update event
  const updateEvent = useCallback(
    async (
      event: Event,
      data: FormValues,
      startDatetime: string,
      endDatetime: string,
      updateScope: ChangeEventScopeType
    ) => {
      const result = await updateEventMutation({
        event: {
          id: event.id,
          eventKindId: data.eventKindId ?? undefined,
          subject: data.subject as string,

          startDatetime: startDatetime ?? undefined,
          endDatetime: endDatetime ?? undefined,

          scope: data.scope,

          eventAttendeeIds: data.eventAttendeeIds,

          confirmAttendance: data.confirmAttendance ?? false,
          allowNonAttendees: data.allowNonAttendees ?? false,
          approvalRequired: data.approvalRequired ?? false,

          description: data.description ?? undefined,
          needNotification: data.needNotification,

          // TODO: イベント更新APIへのリクエスト処理にタスクを追加する
          // https://break-tmc.atlassian.net/browse/CREW-1222
          tasks: undefined,
          version: event.version,

          updateScope,
          recurrenceRule:
            data.recurrenceRule !== RecurrenceRuleWithNone.None
              ? data.recurrenceRule
              : undefined,
          recurrenceEndDate: data.recurrenceEndDate
            ? dayjs(data.recurrenceEndDate).format(JsonDateFormat.YYYYMMDD)
            : undefined,
          isAllDay: data.isAllDay,
        },
      }).unwrap()

      if (result.event) {
        const objectEventMessage: ObjectEventMessage<EventEntry> = {
          eventType: NotifyEventType.Updated,
          id: result.event.id,
          object: result.event,
        }
        dispatch(notifyEventEvent(objectEventMessage))

        dispatch(updateIsExpandedDescription(false))
      }

      return result
    },
    [dispatch, updateEventMutation]
  )

  // Delete event
  const deleteEvent = useCallback(
    async (
      eventId: string,
      version: number,
      deleteScope: ChangeEventScopeType
    ) => {
      await deleteEventMutation({
        eventId,
        version,
        deleteScope,
      }).unwrap()
      const objectEventMessage: ObjectEventMessage<EventEntry> = {
        eventType: NotifyEventType.Deleted,
        id: eventId,
        object: undefined,
      }
      dispatch(notifyEventEvent(objectEventMessage))
    },
    [deleteEventMutation, dispatch]
  )

  //set data source for project category
  // TODO: Web: イベント分類の非表示対応
  // https://break-tmc.atlassian.net/browse/CREW-15049
  // const eventCategoryDataSource = useEventCategoryDataSource(projectId)

  //custom validate start date and end date
  const validateDate = useCallback(() => {
    const startDate = dayjs(getValues('startDate')).format(
      JsonDateFormat.YYYYMMDD
    )
    const endDate = dayjs(getValues('endDate')).format(JsonDateFormat.YYYYMMDD)

    if (startDate && endDate && startDate <= endDate) {
      clearErrors('startDate')
      clearErrors('endDate')
      return true
    }

    return false
  }, [clearErrors, getValues])

  //custom validate start time and end time
  const validateTime = useCallback(() => {
    const isAllDay = getValues('isAllDay')
    const startDate = dayjs(getValues('startDate')).format(
      JsonDateFormat.YYYYMMDD
    )
    const startTime = getValues('startTime')?.getTime()
    const endDate = dayjs(getValues('endDate')).format(JsonDateFormat.YYYYMMDD)
    const endTime = getValues('endTime')?.getTime()

    if (startDate === endDate) {
      if (startTime && endTime && startTime >= endTime && !isAllDay) {
        return false
      }
    }

    clearErrors('endTime')
    clearErrors('startTime')
    return true
  }, [clearErrors, getValues])

  //check is date when user enter in input datepicker
  const validateIsDate = useCallback((value: Date | null) => {
    //when date enter invalid date, datepicker response undefined
    if (typeof value === 'undefined') {
      return false
    }

    return true
  }, [])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      eventKindId: {},
      subject: {
        required: t('message.general.required'),
        maxLength: {
          value: 100,
          message: t('message.general.maxLength', {
            name: t('label.subject'),
            value: 100,
          }),
        },
      },
      startDate: {
        required: t('message.general.required'),
        validate: {
          always: () => validateDate() || t('message.meeting.invalidStartDate'),
          checkDate: () =>
            validateIsDate(getValues('startDate')) ||
            t('message.general.invalidDate'),
        },
      },
      startTime: {
        required: t('message.general.required'),
        validate: {
          always: () => validateTime() || t('message.meeting.invalidStartTime'),
          checkDate: () =>
            validateIsDate(getValues('startTime')) ||
            t('message.general.invalidDate'),
        },
      },
      endDate: {
        required: t('message.general.required'),
        validate: {
          always: () => validateDate() || t('message.meeting.invalidEndDate'),
          checkDate: () =>
            validateIsDate(getValues('endDate')) ||
            t('message.general.invalidDate'),
        },
      },
      endTime: {
        required: t('message.general.required'),
        validate: {
          always: () => validateTime() || t('message.meeting.invalidEndTime'),
          checkDate: () =>
            validateIsDate(getValues('endTime')) ||
            t('message.general.invalidDate'),
        },
      },
      // not validate below
      description: {},
      confirmAttendance: {},
      // TODO: Web: イベント分類の非表示対応
      // https://break-tmc.atlassian.net/browse/CREW-15049
      // eventCategoryId: {},
      eventAttendeeIds: {
        required: t('message.general.required'),
      },
      needNotification: {},
      scope: {
        required: t('message.general.required'),
      },
      allowNonAttendees: {},
      approvalRequired: {},
      recurrenceEndDate: {
        required: t('message.general.required'),
        validate: {
          checkDate: () =>
            validateIsDate(getValues('recurrenceEndDate')) ||
            t('message.general.invalidDate'),
        },
      },
      recurrenceRule: {
        required: t('message.general.required'),
      },
      eventType: {
        required: t('message.general.required'),
      },
      entityType: {
        required: t('message.general.required'),
      },
      entityRecordId: {
        required: t('message.general.required'),
      },
      isAllDay: {},
    }),
    [getValues, t, validateDate, validateIsDate, validateTime]
  )

  return {
    handleSubmit,
    control,
    formState,
    reset,
    getValues,
    setValue,
    clearErrors,
    setError,
    trigger,
    validateRules,

    eventAttendeeDataSource,
    eventKindDataSource,
    recurrenceOptionDataSource,
    eventScopeDataSource,
    eventTypeDataSource,
    entityTypeDataSource,
    entityRecordDataSource,
    memberDataSource,

    insertEvent,
    updateEvent,
    deleteEvent,
    isLoadingInsertEvent,
    isLoadingUpdateEvent,
    isLoadingDeleteEvent,
  }
}
