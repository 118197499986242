import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Resource, SchedulerTypes } from 'devextreme-react/scheduler'
import { useProjectDetailTaskListTimelineScheduler } from './useProjectDetailTaskListTimelineScheduler'
import dayjs from '@crew/modules/dayjs'
import {
  CrewTaskTooltip,
  TaskTooltip,
} from 'components/elements/crewTaskTooltip/crewTaskTooltip'
import { Task } from '@crew/models/domain'
import { useParams, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { DetailTaskSearchOptions, TaskDetailListTabs } from 'enums/app'
import { getParamAsArray, getParamAsDate, getParamAsString } from 'utils'
import { CrewScheduler } from 'components/devextreme/crewScheduler/crewScheduler'
import { useModal } from 'components/layouts/modal/useModal'
import { JsonDateFormat } from '@crew/enums/system'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { getDefaultTabValue } from '@crew/utils/enum'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { useTranslation } from '@crew/modules/i18n'

const groups = ['assignToUser.id']
const views: SchedulerTypes.ViewType[] = ['timelineMonth']

type AppointmentType = {
  data: { appointmentData: Task }
}

export const ProjectDetailTaskListTimelineScheduler = memo(() => {
  const { navigate } = useCrewNavigate()
  const { projectId } = useParams()
  const { t } = useTranslation()

  /** ダイアログ */
  // タスク登録ダイアログ
  const [
    isProjectDetailTaskEntryDialogOpen,
    openProjectDetailTaskEntryDialog,
    closeProjectDetailTaskEntryDialog,
  ] = useModal()

  const [dueDate, setDueDate] = useState<Date>()
  const [assignToUserId, setAssignToUserId] = useState<string>()

  const [searchParams] = useSearchParams()
  const params = useMemo(
    () => qs.parse(searchParams.toString()),
    [searchParams]
  )

  const {
    usersDataSource,
    tasksDataSource,
    taskStateDataSource,
    tasksSchedulerRef,
    taskEventMessage,
  } = useProjectDetailTaskListTimelineScheduler()

  useEffect(() => {
    const filterParams = {
      keyword: getParamAsString(DetailTaskSearchOptions.Keyword.id, params),
      taskKindIds: getParamAsArray(
        DetailTaskSearchOptions.TaskKindId.id,
        params
      ),
      assignToUser: getParamAsString(
        DetailTaskSearchOptions.AssignToUser.id,
        params
      ),
      taskStateIds: getParamAsArray(
        DetailTaskSearchOptions.TaskStateId.id,
        params
      ),
      taskStateTypes: getParamAsArray(
        DetailTaskSearchOptions.TaskStateType.id,
        params
      ),
      taskPriorities: getParamAsArray(
        DetailTaskSearchOptions.TaskPriority.id,
        params
      )?.map((taskPriority) => Number(taskPriority)), // string[] -> number[]
      taskCategoryIds: getParamAsArray(
        DetailTaskSearchOptions.TaskCategoryId.id,
        params
      ),
      startDate: getParamAsDate(DetailTaskSearchOptions.StartDate.id, params),
      dueDate: getParamAsDate(DetailTaskSearchOptions.DueDate.id, params),
      createdById: getParamAsString(
        DetailTaskSearchOptions.CreatedById.id,
        params
      ),
      updatedById: getParamAsString(
        DetailTaskSearchOptions.UpdatedById.id,
        params
      ),
      createdAt: getParamAsDate(DetailTaskSearchOptions.CreatedAt.id, params),
      updatedAt: getParamAsDate(DetailTaskSearchOptions.UpdatedAt.id, params),
    }

    tasksDataSource.filter(filterParams)

    // データソースがロードされていないと実行時エラーが発生するため処理を中断する
    if (tasksDataSource.isLoaded()) {
      tasksDataSource.load()
    }
  }, [params, tasksDataSource])

  // タスクの更新・削除が行われたときのみカスタムデータソースをリロード
  useEffect(() => {
    if (taskEventMessage) {
      // データソースがロードされていないと実行時エラーが発生するため処理を中断する
      if (!tasksDataSource.isLoaded()) return

      tasksDataSource.reload()
    }
  }, [taskEventMessage, tasksDataSource])

  // カレンダーセルクリック時のデフォルトツールチップ表示を抑制
  // NOTE: onCellClickでe.cancel = trueとしてもセルクリック時は抑制できるが、
  //       編集ダイアログ表示時になぜかツールチップもセットで表示されてしまうため、
  //       ツールチップ自体の起動タイミングでキャンセルできるonAppointmentFormOpeningを使用
  const handleAppointmentFormOpening = useCallback(
    (event: SchedulerTypes.AppointmentFormOpeningEvent) => {
      event.cancel = true

      // Set due date to state
      setDueDate(
        new Date(
          dayjs
            .utc(event.appointmentData?.dueDate)
            .format(JsonDateFormat.YYYYMMDD)
        )
      )

      // set assign to user id to state
      setAssignToUserId(event.appointmentData?.['assignToUser.id'])

      // Open project detail task entry dialog
      openProjectDetailTaskEntryDialog()
    },
    [openProjectDetailTaskEntryDialog]
  )

  // タスク登録完了
  const handleTaskRegistered = useCallback(
    (taskId: string) => {
      // タスク詳細画面に遷移
      navigate(`/tasks/${taskId}/${getDefaultTabValue(TaskDetailListTabs)}`)

      //close project detail task entry dialog
      closeProjectDetailTaskEntryDialog()
    },
    [navigate, closeProjectDetailTaskEntryDialog]
  )

  // close appointment tooltip
  const handleCloseTooltip = useCallback(() => {
    tasksSchedulerRef.current?.instance.hideAppointmentTooltip()
  }, [tasksSchedulerRef])

  // Render appointment tooltip
  const renderAppointmentTooltip = useCallback(
    (item: AppointmentType) => {
      // Task item data of appointment tooltip
      const task: Task = item.data.appointmentData
      // Data to display in tooltip
      const taskTooltip: TaskTooltip = {
        id: task.id,
        subject: task.subject,
        description: task.description,
        startDate: task.startDate,
        dueDate: task.dueDate,
        assignToUser: task.assignToUser,
        taskPriority: task.taskPriority,
        taskKind: task.taskKind,
        taskState: task.taskState,
        estimatedWorkTimes: task.estimatedWorkTimes,
        actualWorkTimes: task.actualWorkTimes,
        remainingWorkTimes: task.remainingWorkTimes,
      }

      return (
        <CrewTaskTooltip
          data={taskTooltip}
          onCloseTooltip={handleCloseTooltip}
        />
      )
    },
    [handleCloseTooltip]
  )

  // appointmentComponentのレンダリング
  // NOTE: 公式のカスタムテンプレートを参考
  //       https://js.devexpress.com/Demos/WidgetsGallery/Demo/Scheduler/CustomTemplates/React/Light/
  const appointment = useCallback((model: any) => {
    return <>{model.data.appointmentData.subject}</>
  }, [])

  // Appointment collector render
  const renderAppointmentCollector = useCallback(
    (data: SchedulerTypes.AppointmentCollectorTemplateData) => {
      return (
        <div className="crew-link">
          {t('label.selectMore', {
            value: data.appointmentCount,
          })}
        </div>
      )
    },
    [t]
  )

  // Event handler for when an appointment is rendered
  const handleAppointmentRendered = useCallback(
    (e: SchedulerTypes.AppointmentRenderedEvent) => {
      e.appointmentElement.style.height = 'auto'
    },
    []
  )

  return (
    <div className="overflow-auto">
      <CrewScheduler
        id="taskListTimelineScheduler"
        timeZone={dayjs.tz.guess()}
        dataSource={tasksDataSource}
        defaultCurrentView="timelineMonth"
        defaultCurrentDate={new Date()}
        textExpr="subject"
        startDateExpr="startDate"
        endDateExpr="dueDate"
        ref={tasksSchedulerRef}
        groups={groups}
        views={views}
        appointmentComponent={appointment}
        onAppointmentRendered={handleAppointmentRendered}
        appointmentTooltipComponent={renderAppointmentTooltip}
        onAppointmentFormOpening={handleAppointmentFormOpening}
        appointmentCollectorRender={renderAppointmentCollector}
        maxAppointmentsPerCell={3}
        height="100%"
      >
        <Resource
          fieldExpr="taskState.id"
          dataSource={taskStateDataSource}
          useColorAsDefault={true}
          colorExpr="displayColor"
        />

        <Resource
          fieldExpr="assignToUser.id"
          displayExpr="displayName"
          allowMultiple={false}
          dataSource={usersDataSource}
          label=""
        />
      </CrewScheduler>

      {/* タスク登録ダイアログ */}
      <TaskEntryDialog
        isEditMode={false}
        title={t('label.addTaskTitle')}
        onSubmit={handleTaskRegistered}
        isOpen={isProjectDetailTaskEntryDialogOpen}
        onClose={closeProjectDetailTaskEntryDialog}
        projectId={projectId}
        defaultDueDate={dueDate}
        defaultAssignToUserId={assignToUserId}
      />
    </div>
  )
})
