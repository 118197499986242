import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { LoadIndicator } from 'devextreme-react'
import { useEventDetailPanel } from 'features/project/components/eventDetailPage/components/eventDetailPanel/useEventDetailPanel'
import { FC, memo, useState } from 'react'
import {
  EndMeetingActionType,
  EntityType,
  EventType,
  MeetingAttendeeJoinState,
} from '@crew/enums/domain'
import { WebMeetingJoinDialog } from 'features/webMeeting/components/webMeetingJoinDialog/webMeetingJoinDialog'
import { CrewMeetingArchive } from 'components/elements/crewMeetingArchive/crewMeetingArchive'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { CrewPopover } from 'components/devextreme/crewPopover'
import ReactRouterPrompt from 'react-router-prompt'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewGroupAvatar } from 'components/elements/crewGroupAvatar/crewGroupAvatar'
import { useProjectPermissions } from '@crew/hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { showMeetingPanelUpdated } from 'features/project/components/eventDetailPage/states/eventDetailPageSlice'
import { setIsHost } from 'features/webMeeting/states/webMeetingSlice'
import { useModal } from 'components/layouts/modal/useModal'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { MeetingStatus } from 'modules/amazon-chime-sdk-component-library-devextreme/types'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useTranslation } from '@crew/modules/i18n'
import { useEventDetailPageContext } from '../../useEventDetailPage'
import { getDefaultTabValue } from '@crew/utils/enum'
import { EventDetailTabs, ProjectListTabs } from 'enums/app'
import {
  useMeetingManager,
  useMeetingStatus,
} from 'modules/amazon-chime-sdk-component-library-devextreme'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { useLazyCanJoinWebMeetingQuery } from '@crew/apis/project/projectApis'
import { useToast } from 'hooks/useToast'
import BaselineVideocam from '~icons/ic/baseline-videocam'
import BaseOutlineVideocam from '~icons/ic/outline-videocam'
import { EventKindRef, UserRef } from '@crew/models/refs'
import { EventRecording } from '@crew/models/domain'
import { useCrewAttendees } from 'components/chime/crewAttendees/useCrewAttendees'
import { CrewDropDownButton } from 'components/elements/crewDropDownButton/crewDropDownButton'
import SensorDoor from '~icons/material-symbols/sensor-door'
import CloseCircle from '~icons/mdi/close-circle'
import {
  LIFE_TIME_MS,
  TOPIC_MEETING_REJECT_USER,
  TOPIC_MEETING_USER_JOIN_STATE_CHANGED,
} from 'configs/constants'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import { DataMessage } from 'amazon-chime-sdk-js'
import { EventScheduleEntryDialog } from 'features/event/components/eventScheduleEntryDialog/eventScheduleEntryDialog'

type DropDownItem = {
  text: string
  value: string
  icon: JSX.Element
}

type Event = {
  id: string
  subject: string
  startDatetime: string
  endDatetime: string
  isAllDay: boolean
  confirmAttendance: boolean
  entityType: EntityType | null
  entityRecordId: string | null
  eventKind: EventKindRef | null
  eventAttendees: UserRef[]
  isMeeting: boolean
  eventRecordings: EventRecording[]
  eventType: EventType
}

export type EventProps = {
  event: Event
  isLoadingEvent?: boolean
  isErrorEvent?: boolean
}

export const EventDetailPanel: FC<EventProps> = memo((props) => {
  const { endMeeting, isLoadingEndMeeting } = useEventDetailPanel()
  const audioVideo = useAudioVideo()

  const [isEventEntryDialogOpen, openEventEntryDialog, closeEventEntryDialog] =
    useModal()

  const [isOpenWebMeetingJoinDialog, setIsOpenWebMeetingJoinDialog] =
    useState<boolean>(false)

  const { navigate } = useCrewNavigate()

  const dispatch = useAppDispatch()

  const [showApiErrors] = useShowApiErrors()
  const { t } = useTranslation()

  const { warn } = useToast()

  const [
    lazyCanJoinWebMeetingQuery,
    { isLoading: isLoadingCanJoinWebMeeting },
  ] = useLazyCanJoinWebMeetingQuery()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // Update event finish
  const handleEventUpdated = useCallback(
    (
      eventId: string,
      eventType: EventType,
      isNavigate: boolean = true,
      deleteFlg: boolean = false
    ) => {
      if (isNavigate) {
        if (deleteFlg) {
          if (eventType === EventType.Project) {
            // Navigate to project detail after delete event
            navigate(
              `/projects/${props.event.entityRecordId}/${getDefaultTabValue(
                ProjectListTabs
              )}`
            )
          } else {
            // Navigate to group monthly schedule after delete event
            navigate('/schedule/groupMonthly')
          }
        } else {
          // Navigate to event detail after update event
          navigate(`/events/${eventId}/${getDefaultTabValue(EventDetailTabs)}`)
        }
      }

      // Close entry dialog
      closeEventEntryDialog()
    },
    [closeEventEntryDialog, navigate, props.event.entityRecordId]
  )

  const meetingManager = useMeetingManager()

  const { localAttendeeUser } = useCrewAttendees()

  // ユーザーがWeb会議の開催者かどうかの情報
  const isHost = useAppSelector((state) => state.webMeeting.isHost)

  // 会議中かどうかの状態を取得
  const meetingStatus = useMeetingStatus()

  // 会議の退室判定
  const isEndMeeting = useAppSelector((state) => state.webMeeting.isEndMeeting)

  const joinState = useAppSelector((state) => state.webMeeting.joinState)

  const [isMeetingEnded, setIsMeetingEnded] = useState(false)
  const [isRejectUser, setIsRejectUser] = useState(false)

  // 会議終了処理
  useEffect(() => {
    if (meetingStatus === MeetingStatus.Ended) {
      setIsMeetingEnded(true)
    }
  }, [meetingStatus])

  useEffect(() => {
    if (!audioVideo) {
      console.warn('AudioVideo object is not available')
      return
    }

    // Subscribe processing when host reject user to join meeting
    const onRejectUser = async (dataMessage: DataMessage) => {
      const { userId } = dataMessage.json()

      // If the user is not the target user, do not process
      if (userId !== localAttendeeUser?.chimeAttendeeId) return

      // Send realtime message that the meeting attendees has changed
      audioVideo.realtimeSendDataMessage(
        TOPIC_MEETING_USER_JOIN_STATE_CHANGED,
        undefined, // We don't need display toast message, so we don't need to set message
        LIFE_TIME_MS
      )

      // leave meeting
      await meetingManager.leave()

      setIsRejectUser(true)
    }

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      TOPIC_MEETING_REJECT_USER,
      onRejectUser
    )

    return () => {
      if (audioVideo) {
        // Unsubscribe process related to host reject user to join meeting
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
          TOPIC_MEETING_REJECT_USER
        )
      }
    }
  }, [
    audioVideo,
    dispatch,
    localAttendeeUser?.chimeAttendeeId,
    meetingManager,
    showApiErrors,
    t,
  ])

  // 会議終了処理
  const handleEndMeeting = useCallback(
    async (actionType: EndMeetingActionType) => {
      if (meetingManager.meetingId) {
        try {
          // 会議終了API
          await endMeeting(meetingManager.meetingId, actionType)
          // 会議終了処理（デバイス関連）
          await meetingManager.leave()

          setIsMeetingEnded(true)
        } catch (err) {
          showApiErrors(err)
        }
      }
      // 一覧表示コンポーネントに切り替え
      dispatch(showMeetingPanelUpdated(false))
      dispatch(setIsHost(false))
    },
    [dispatch, endMeeting, meetingManager, showApiErrors]
  )

  // 会議を退室ボタンクリック処理
  const handleLeaveMeetingButtonClick = useCallback(async () => {
    if (!audioVideo) return

    if (joinState === MeetingAttendeeJoinState.Waiting) {
      // Send realtime message that the meeting attendees has changed
      audioVideo.realtimeSendDataMessage(
        TOPIC_MEETING_USER_JOIN_STATE_CHANGED,
        undefined, // We don't need display toast message, so we don't need to set message
        LIFE_TIME_MS
      )
    }

    // 会議終了処理
    handleEndMeeting(EndMeetingActionType.LeaveMeeting)
  }, [audioVideo, handleEndMeeting, joinState])

  // 全画面表示のときに表示される会議終了・退室ボタン押下による会議終了処理
  useEffect(
    () => {
      isEndMeeting && handleEndMeeting(EndMeetingActionType.CloseMeeting)
    },
    // isEndMeetingのみ依存とする
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEndMeeting]
  )

  // Event handle when join meeting button is clicked
  const handleOpenWebMeetingJoinDialog = useCallback(async () => {
    try {
      // Call the API to check whether the logged in user can join the meeting or not
      const canJoinWebMeetingResult = await lazyCanJoinWebMeetingQuery({
        eventId: props.event.id,
      }).unwrap()

      if (!canJoinWebMeetingResult?.canJoin) {
        // 参加不可の理由をtoastで表示する
        warn(t('message.meeting.' + canJoinWebMeetingResult.messageCode))
        return
      }

      setIsOpenWebMeetingJoinDialog(true)
    } catch (err) {
      showApiErrors(err)
    }
  }, [lazyCanJoinWebMeetingQuery, props.event.id, showApiErrors, t, warn])

  // Event handle when close button on WebMeetingJoinDialog is clicked
  const handleCloseWebMeetingJoinDialog = useCallback(() => {
    setIsOpenWebMeetingJoinDialog(false)
  }, [])

  // ミーティング中か
  const inMeeting = useMemo(() => {
    const isMeetingNotStarted =
      meetingStatus === MeetingStatus.Loading ||
      meetingStatus === MeetingStatus.Left ||
      meetingStatus === MeetingStatus.Ended

    // 会議開始の失敗
    const isMeetingFailed =
      meetingStatus === MeetingStatus.Failed ||
      meetingStatus === MeetingStatus.TerminalFailure

    return !isMeetingNotStarted && !isMeetingFailed
  }, [meetingStatus])

  // Get event permission for logged in user
  const {
    hasPrjEventEditPermission,
    hasPrjFileDownloadPermission,
    hasPrjFileDeletePermission,
  } = useProjectPermissions(EntityType.Event, props.event.id)

  const { setConfirmAttendance } = useEventDetailPageContext()
  useEffect(() => {
    if (props.event) {
      setConfirmAttendance(props.event.confirmAttendance)
    }
  }, [props.event, setConfirmAttendance])

  const meetingDropdownItems: DropDownItem[] = useMemo(() => {
    return [
      {
        text: t('action.endMeeting'),
        value: 'endMeeting',
        icon: <CloseCircle width={24} height={24} />,
      },
    ]
  }, [t])

  // Handle drop down item click
  const handleDropDownItemClick = useCallback(
    (item: DropDownItem) => {
      if (item.value === 'endMeeting') {
        handleEndMeeting(EndMeetingActionType.CloseMeeting)
      }
    },
    [handleEndMeeting]
  )

  // Render meeting action button
  const renderMeetingAction = useCallback(() => {
    // leave meeting button
    const renderLeaveButton = () => {
      if (localAttendeeUser?.isHost) {
        // 主催者の場合：「会議を退室」「会議を終了」ボタンを表示
        return (
          <CrewDropDownButton
            text={t('action.leaveMeeting')}
            items={meetingDropdownItems}
            type="danger"
            splitButton
            keyExpr="value"
            displayExpr="text"
            icon={<SensorDoor width={24} height={24} />}
            onButtonClick={handleLeaveMeetingButtonClick}
            onItemClick={handleDropDownItemClick}
          />
        )
      } else {
        // 主催者ではない場合：「会議を退室」ボタンを表示
        return (
          <CrewButton
            icon={<SensorDoor width={24} height={24} />}
            text={isHost ? t('action.endMeeting') : t('action.leaveMeeting')}
            type="danger"
            onClick={handleLeaveMeetingButtonClick}
            disabled={isLoadingEndMeeting}
          />
        )
      }
    }

    // meeting has ended button
    const renderMeetingEndedButton = () => (
      <CrewButton
        icon={<BaselineVideocam width={24} height={24} />}
        text={t('label.meetingHasEnded')}
        disabled
        type="normal"
      />
    )

    // 会議の入室が拒否されました
    const renderRejectUserButton = () => (
      <CrewButton
        icon={<BaselineVideocam width={24} height={24} />}
        text={t('label.meetingJoinRejected')}
        disabled
        type="normal"
      />
    )

    // join meeting button
    const renderJoinButton = () => (
      <CrewButton
        icon={<BaselineVideocam width={24} height={24} />}
        text={
          props.event.isMeeting
            ? t('action.joinMeeting')
            : t('action.startMeeting')
        }
        type="primary"
        onClick={handleOpenWebMeetingJoinDialog}
        disabled={isLoadingCanJoinWebMeeting}
      />
    )

    // Determine which button to render based on meeting state
    if (inMeeting) {
      return renderLeaveButton()
    } else if (isRejectUser) {
      return renderRejectUserButton()
    } else if (isMeetingEnded) {
      return renderMeetingEndedButton()
    } else {
      return renderJoinButton()
    }
  }, [
    handleDropDownItemClick,
    handleLeaveMeetingButtonClick,
    handleOpenWebMeetingJoinDialog,
    inMeeting,
    isLoadingCanJoinWebMeeting,
    isLoadingEndMeeting,
    isMeetingEnded,
    isHost,
    isRejectUser,
    localAttendeeUser?.isHost,
    meetingDropdownItems,
    props.event.isMeeting,
    t,
  ])

  if (props.isErrorEvent) {
    return null
  }
  if (props.isLoadingEvent) {
    return <LoadIndicator height="1em" width="1em" />
  }

  if (!props.event) {
    return null
  }

  return (
    <>
      <div className="flex flex-col gap-2.5 p-2.5">
        <div className="w-full flex justify-between items-center gap-2.5">
          {/* ページタイトル */}
          <div className="flex gap-2.5 items-center">
            <BaseOutlineVideocam width={48} height={48} className="shrink-0" />

            <div className="flex flex-col">
              {/* 件名 */}
              <div
                className="text-2xl line-clamp-1 break-all"
                title={`${props.event.subject}`}
              >
                {props.event.subject}
              </div>

              {/* 関連先リンク */}
              {props.event.entityType && props.event.entityRecordId && (
                <CrewRelatedItemLink
                  entityType={props.event.entityType}
                  id={props.event.entityRecordId}
                  className="line-clamp-1 break-all"
                  asText={props.event.eventType === EventType.Personal}
                />
              )}
            </div>

            {/* 活動種別バッジ */}
            {props.event.eventKind && (
              <CrewBadge displayColor={props.event.eventKind.displayColor}>
                {props.event.eventKind.name}
              </CrewBadge>
            )}

            {/* 会議中のアイコン  */}
            {(props.event.isMeeting ||
              inMeeting ||
              // If isEndMeeting is not added, icon meeting will not display after click button 会議を開始
              isEndMeeting) && (
              //TODO: inMeetingの使い方について以下のタスクで整理する
              //      https://break-tmc.atlassian.net/browse/CREW-8098
              <>
                <div className="cursor-pointer" id="isMeeting">
                  <BaselineVideocam
                    width={24}
                    height={24}
                    className="crew-badge-icon-red"
                  />
                </div>
                <CrewPopover
                  target="#isMeeting"
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  position="top"
                >
                  {t('message.meeting.webMeetingInProgress')}
                </CrewPopover>
              </>
            )}
          </div>

          <div className="flex gap-x-2.5 ml-auto">
            {/* Display meeting button */}
            {renderMeetingAction()}

            {/* 編集ボタン */}
            {hasPrjEventEditPermission && (
              // 「イベントの編集」権限を持っている場合　：表示する
              <CrewButton
                text={`${t('action.edit')}`}
                onClick={openEventEntryDialog}
                stylingMode="outlined"
                disabled={props.event.isMeeting || inMeeting}
              />
            )}
          </div>
        </div>

        <div className="flex items-center gap-x-7 text-crew-gray-4-light dark:text-crew-gray-1-dark">
          {/* 開始日 */}
          <div className="flex flex-col gap-y-0.5">
            <span className="crew-text-gray-4">{t('label.start')}</span>
            <span>
              {/* If 終日 = true, show exact date from backend */}
              {props.event.isAllDay
                ? t('format.date', {
                    value: props.event.startDatetime,
                    timeZone: defaultUserProfileRegion,
                  })
                : t('format.datetime', {
                    value: props.event.startDatetime,
                    timeZone: defaultUserProfileRegion,
                  })}
            </span>
          </div>
          <span>-</span>
          {/* 終了日 */}
          <div className="flex flex-col gap-y-0.5">
            <span className="crew-text-gray-4">{t('label.end')}</span>
            <span>
              {/* If 終日 = true, show exact date from backend */}
              {props.event.isAllDay
                ? t('format.date', {
                    value: props.event.endDatetime,
                    timeZone: defaultUserProfileRegion,
                  })
                : t('format.datetime', {
                    value: props.event.endDatetime,
                    timeZone: defaultUserProfileRegion,
                  })}
            </span>
          </div>

          {/* 参加者 */}
          {props.event.eventAttendees.length > 0 && (
            <div className="flex flex-col gap-y-0.5 items-start">
              <span className="crew-text-gray-4">{t('label.attendee')}</span>
              <div className="flex items-center shrink-0">
                <CrewGroupAvatar
                  groupAvatar={props.event.eventAttendees}
                  //  hidden icon add member
                  showAddAvatar={false}
                  size={CrewAvatarSize.xs}
                />
              </div>
            </div>
          )}
          {/* 分類 */}
          {/* TODO: Web: イベント分類の非表示対応
          https://break-tmc.atlassian.net/browse/CREW-15049
          {props.event.eventCategory?.name && (
            <div className="flex flex-col gap-y-0.5">
              <span className="crew-text-gray-4">{t('label.category')}</span>
              <span>{props.event.eventCategory?.name}</span>
            </div>
          )} */}
        </div>

        {/* 録画アーカイブファイル */}
        {props.event.eventRecordings.length > 0 && (
          <>
            <CrewMeetingArchive
              archiveFiles={props.event.eventRecordings}
              // 「ファイルの削除」権限を持っている場合　：表示する
              // 「ファイルの削除」権限を持っていない場合：表示しない
              showDeleteButton={hasPrjFileDeletePermission}
              // 「ファイルのダウンロード」権限を持っている場合　：表示する
              // 「ファイルのダウンロード」権限を持っていない場合：表示しない　※ファイル名クリック時のストリーミング再生は可能
              showDownloadButton={hasPrjFileDownloadPermission}
            />
          </>
        )}

        {/* イベント編集ダイアログ */}
        <EventScheduleEntryDialog
          isEditMode={true}
          title={t('label.editMeeting')}
          isOpen={isEventEntryDialogOpen}
          onClose={closeEventEntryDialog}
          eventId={props.event.id}
          onSubmit={handleEventUpdated}
        />

        {/* 会議参加ダイアログ */}
        {isOpenWebMeetingJoinDialog && (
          <WebMeetingJoinDialog
            eventId={props.event.id}
            isOpen={isOpenWebMeetingJoinDialog}
            onClose={handleCloseWebMeetingJoinDialog}
          />
        )}
      </div>

      {/* 会議に参加・終了ボタン */}
      <ReactRouterPrompt when={inMeeting}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <CrewConfirmDialog
              isOpen={isActive}
              message={t('label.comfirmLeaveMetting')}
              onPermitButtonClick={() => {
                handleLeaveMeetingButtonClick()
                onConfirm()
              }}
              onCancelButtonClick={onCancel}
            />
          )
        }
      </ReactRouterPrompt>
    </>
  )
})
